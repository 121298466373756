
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup () {
    const chatBox = ref(false)
    const showChatBox = () => {
      chatBox.value = !chatBox.value
    }

    return {
      chatBox,
      showChatBox
    }
  }
})
